@font-face {
  font-family: "Fustat";
  src: url('fonts/Fustat-Medium.ttf');
  font-style: normal;
}

body {
  font-family: "Fustat";
}

.home_page .logo {
  display: flex;
  width: 684px;
  height: 184.266px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-left: calc(50% - 342px);
  margin-top: 250px;
}

.home_page .mappa {
  width: 100%;
  margin: 157px 0px;
}

.footer span {
  color: var(--d-61-c-27, #D61C27);
  font-family: Fustat;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.footer {
  width: calc(100% - 314px);
  margin-left: 157px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  padding-bottom: 60px;
}